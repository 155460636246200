.homeWrapper{ 
      height: 100%; 
      position: relative;
      z-index: 99;
      background: radial-gradient(circle at top left,#47866B,#1D6C58,#17584B,#20262E 100% );
}
.bgWrapper {
      height: 100vh;
      
      position: absolute;
}
.contentWrapper{
      // background: radial-gradient(circle at top left,#47866B,#1D6C58,#17584B,#20262E 100% );
      background: var(--color-black-light);
}
.desktopBgTop{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
}
.desktopBgBottom{
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
}
.loaderWrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      overflow: hidden;
}
.container {
  width: 100%;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

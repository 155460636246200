.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;

  .leftContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .companyName {
      font-size: 18px;
      line-height: 28px;
      margin-left: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 175px;
    }
  }

  .pillsWrapper {
    display: flex;
    flex-direction: row;

    .pillContainer {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 16px;
      padding: 4px 16px;
      background-color: #605081;

      cursor: pointer;

      &:first-of-type {
        margin-right: 4px;
      }

      .ts1 {
        color: #fff;
        font-family: var(--F2);
        font-size: var(--Fs2);
        line-height: var(--Lh2);
        font-weight: var(--Fw3);

        margin-left: 8px;
      }
    }
  }
}

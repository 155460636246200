.promptEntryWrapper {
  width: 600px;
  background-color: var(--color-black-dark);

  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 24px;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  background: var(--color-green-2);
  margin-right: 6px;

  cursor: pointer;
  transition: all 0.2s;
}

.input {
  border-radius: 0;
  border: none;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  max-height: 92px;

  padding: 12px 20px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 24px;

  line-height: 24px;
  background-color: var(--color-black-dark);
  color: white;
  box-shadow: none;
  border: none;

  &::placeholder {
    color: var(--color-grey);
  }

  &:focus {
    outline: 0;
    //   height: 200px; /* Adjust the max height on focus if needed */
    border-color: var(--color-green-2);
  }

  &:disabled {
    &::placeholder {
      // color: var(--input-text-color-disable);
    }
  }
}

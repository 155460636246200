.promptWrapper {
  background: var(--color-black-light);
  height: 100%;
}

.contentWrapper {
  height: calc(100% - 84px);
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.feedbackWrapper {
  align-self: flex-end;
  margin-right: 68px;
  margin-bottom: 50px;
}

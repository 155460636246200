.banner3Wrapper {
  display: flex;
  flex-direction: column;
  background-color: rgb(55, 55, 129);
  padding: 10px;
  color: white;

  .subText {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .imageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .image {
      width: auto;
      height: auto;
      max-width: 100px;
      max-height: 100px;

      border-radius: 8px;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 12px;

    .button {
      background-color: blue;
    }
  }
}

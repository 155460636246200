@import '../../theme/responsive.module.scss';

.buttonBase {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.iconWrapper {
  margin-right: 8px;
}
.disabled {
  pointer-events: none;
}
.xs {
      padding: 4px 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-radius: 8px;
      border: 0.5px solid var(--color-primary);
}

.sm {
      padding: 8px 20px;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      border-radius: 8px;
      border: 0.5px solid var(--color-primary);
      @include responsive('mobile') {
            font-size: 12px;
            line-height: 16px;
            border-radius: 6px;
      }
}
.md {
      padding: 12px 60px;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      border-radius: 8px;
      border: 0.5px solid var(--color-primary);
      @include responsive('mobile') {
            padding: 10px 30px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
      }
}

.secondary {
      color: var(--color-white);
      border-color: var(--color-white);
}

.primary {
      background-color: var(--color-primary);
      color: var(--color-black-light);
      border-color: var(--color-primary);
}

.primaryOutlined {
      color: var(--color-primary);
      background-color: var(--color-black-light);
      border-color: var(--color-primary);
}

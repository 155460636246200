.loaderBase {
      border: 2px solid var(--color-disabled);
      border-radius: 50%;
      border-top: 2px solid var(--color-green-2);
      width: 16px;
      height: 16px;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
}
.promptsHistoryWrapper {
  min-width: 350px;
  flex: 0 0 25%;
  // border-right: 2px solid var(--color-grey-dark);
  .listWrapper {
    overflow-y: scroll;
  }
}

.newPromptCard {
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid var(--color-black-dark);
}

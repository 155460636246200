.cardWrapper{
      margin-bottom: 8px;
      height: 64px;
      cursor: pointer;
      background-color: var(--color-black-dark);
      .statusWrapper {
            width: 48px;
            height: 48px;      
      }
      &:hover{
            background: var(--color-grey-dark);
      }
      &.isActive{
            background: var(--color-grey-dark);
      }
      .contentWrapper{
            width: calc(100% - 60px);
      }
}
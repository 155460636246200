@import './responsive.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root{
    /* Typography */
    --font-family: 'Rubik', sans-serif;
    --mobile-width: #{$mobile-width};
      
    /* Colors */
    --color-transparent: transparent;
    --color-primary: #74EC8C;
    --color-black: #000;
    --color-black-light: #20262E;
    --color-black-dark: #111518;
    --color-white: #F2F2F2;
    --color-white-30: rgba(242, 242, 242, 0.3);
    --color-white-40: rgba(242, 242, 242, 0.4);
    --color-grey-dark: #2C3443;
    --color-grey-dark_2: #333A49;
    --color-green-1: #68B878;
    --color-green-2: #31753F;
    --color-grey: #6D7684;
    --color-grey-light: #999FAC;
    --color-grey-light_2: #C2C8D3;
    --color-disabled: #C5C5C5;
    --color-green-dummy-1: #10453C;
    --color-green-dummy-2: #185248;
    
  }

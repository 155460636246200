.headerWrapper {
  display: flex;
  width: 100%;
  padding: 8px;

  .iconImageContainer {
    object-fit: cover;
    width: var(--Sp8) !important;
    height: var(--Sp8) !important;
    padding: var(--Sp2);
    margin: 4px;
    margin-right: 0;
    border-radius: 16px;
  }

  .companyName {
    margin-left: 8px;
    font-size: 14px;
  }
}

.iconButtonWrapper{
      cursor: pointer;
}
.secondary {
      background-color: var(--color-black-light);
      &.selected{
            background-color: var(--color-green-1)
      }
}
.primary {
      background-color: var(--color-green-1);
}
.loading{
      background-color: var(--color-grey-dark);
}
.disabled {
      opacity: 0.4;
      pointer-events: none;
}
.header5Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  padding: 12px;

  .locationWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;

    .locationContentWrapper {
      display: flex;
      flex-direction: column;
      margin-left: 4px;

      .textTitle {
        display: flex;
        flex-direction: row;
        color: black !important;
        align-items: center;
      }

      .textDesc {
        max-width: 221px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: black !important;
      }
    }
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dropdownWrapper {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      .title {
        font-size: 12px;
        line-height: 16px;
      }

      .desc {
        font-size: 10px;
        color: #312838;
        line-height: 14px;
      }
    }
  }
}

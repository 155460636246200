.uiAndFeedbackWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  padding: 40px;
  width: 100%;

  .backgroundWrapper {
    background-color: black;
    padding: 10px;
    border-radius: 32px;

    .uiGeneratorWrapper {
      display: flex;
      flex-direction: column;
      background-color: var(--CGray6);
      width: 320px;
      overflow-y: scroll;
      border-radius: 22px;
      min-height: 544px;
      max-height: 544px;
    }
  }
}

.outputWrapper {
  position: relative;
  // .feedbackWrapper{
  //   position: absolute;
  //   right: -348px;
  //   bottom: 0;
  // }
}

/* Hide the default scrollbar for WebKit-based browsers */
.mobile-container::-webkit-scrollbar {
  width: 0.2rem; /* Set the width of the scrollbar */
}

.mobile-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
}

.mobile-container::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

/* Enable scrollbar for Firefox */
.mobile-container {
  scrollbar-width: thin; /* Set the width of the scrollbar */
  scrollbar-color: transparent transparent; /* Set the color of the scrollbar thumb and track */
}

/* Regular*/
@font-face {
    font-family: 'Rubik';
    font-weight: 400;
    font-style: normal;
    src: url('../assets/fonts/Rubik-Regular.woff') format('woff'),
      url('../assets/fonts/Rubik-Regular.woff2') format('woff2');
  }
  
  /* light */
  @font-face {
    font-family: 'Rubik';
    font-weight: 300;
    font-style: normal;
    src: url('../assets/fonts/Rubik-Light.woff') format('woff'),
      url('../assets/fonts/Rubik-Light.woff2') format('woff2');
  }
  
  /* Medium */
  @font-face {
    font-family: 'Rubik';
    font-weight: 500;
    font-style: normal;
    src: url('../assets/fonts/Rubik-Medium.woff') format('woff'),
      url('../assets/fonts/Rubik-Medium.woff2') format('woff2');
  }
  
  /* Bold */
  @font-face {
    font-family: 'Rubik';
    font-weight: 700;
    font-style: normal;
    src: url('../assets/fonts/Rubik-Bold.woff') format('woff'),
      url('../assets/fonts/Rubik-Bold.woff2') format('woff2');
  }
  
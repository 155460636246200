.x4Wrapper {
  display: grid;
  gap: 10px;
  padding: 20px;
}

.sliderItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 8px 8px 0 0;
  }

  div {
    padding: 2px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
  }
}

.netflixCardsWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 20px 0;

  .netflixItemWrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    .title {
      position: absolute;
      top: 70%;
      text-align: center;
      color: black;
    }
  }
}

:root {
  --section-overlay: white;

  /* colorvariables */
  --CPrimary1: #003580;
  --CPrimary2: #003580;
  --CPrimary3: #fcc2eb;
  --CSecondary1: #605081;
  --CSecondary2: #7966a3;
  --CSecondary3: #f2e8e1;
  --CGray1: #000000;
  --CGray2: #20262e;
  --CGray3: #c7cbd3;
  --CGray4: #eaecf0;
  --CGray5: #f9fafb;
  --CGray6: #ffffff;
  --CIndicatorE1: #9b1b1b;
  --CIndicatorE2: #db1506;
  --CIndicatorE3: #ffdad8;
  --CIndicatorW1: #d07d00;
  --CIndicatorW2: #e80ede;
  --CIndicatorW3: #fffde7;
  --CIndicatorS1: #167814;
  --CIndicatorS2: #06db0f;
  --CIndicatorS3: #ebffe7;

  /* fontvariables (font-family)*/
  --F1: Rubik;
  --F2: Noto;

  /* sizevariables (font-size/line-height) */

  /* --Fs1 : 10px/14;
    --Fs2 : 12px/16;
    --Fs3 : 16px/22;
    --Fs4 : 18px/28;
    --Fs5 : 22px/32;
    --Fs6 : 36px/44; */

  --Fs1: 10px;
  --Fs2: 12px;
  --Fs3: 16px;
  --Fs4: 18px;
  --Fs5: 22px;
  --Fs6: 36px;

  --Lh1: 14px;
  --Lh2: 16px;
  --Lh3: 22px;
  --Lh4: 28px;
  --Lh5: 32px;
  --Lh6: 44px;

  /* weightvariables (font-weight) -- using this instead of light / regular /bold/ bolder*/
  --Fw1: 100;
  --Fw2: 200;
  --Fw3: 300;
  --Fw4: 400;
  --Fw5: 500;
  --Fw6: 600;
  --Fw7: 700;
  --Fw8: 800;
  --Fw8: 900;

  /* textdecorationvariables (font-style)*/
  --Td1: Italic;
  --Td2: Underline;

  /* paragraphspacingvariables */
  --Ps1: 12px;
  --Ps2: 24px;
  --Ps3: 32px;

  /* paragraphindentvariables (text-align) */
  --Pi1: Left;
  --Pi2: Center;
  --Pi3: Right;
  --Pi4: Justify;

  /* textcasevariables */
  --Tc1: UpperCase;
  --Tc2: LowerCase;
  --Tc3: capitalize;
  /* --Tc3 : TitleCase; */

  /* sizing and spacing */
  /* --Sp1 : 2px;
  --Sp2 : 4px;
  --Sp3 : 8px;
  --Sp4 : 12px;
  --Sp5 : 16px;
  --Sp6 : 24px;
  --Sp7 : 32px;
  --Sp8 : 40px;
  --Sp9 : 64px;
  --Sp10 : 96px; */

  /* Border Radius (cards, buttons, components): */
  --Br1: 2px;
  --Br2: 4px;
  --Br3: 8px;
  --Br4: 16px;
  --Br5: 24px;

  /* Border Width: */
  --Bw1: 1px;
  --Bw3: 2px;
  --Bw4: 4px;

  /* Shadow: (type, x, y, blur, spread, color, opacity) */
  /* --Sh1 :  1px 1px 10px 0 #312838;
  --Sh2 :  0px 8px 11px -3px #000000;
  --ShE :  0px 8px 11px -3px #db1506;
  --ShW :  0px 8px 11px -3px #e80ede;
  --ShS :  0px 8px 11px -3px #06db0f; */

  /* sizing variables */
  --Sz1: 360px;
  --Sz2: 320px;
  --Sz22: 256px;
  --Sz3: 144px;
  --Sz4: 96px;
  --Sz45: 64px;
  --Sz5: 44px;
  --Sz6: 32px;
  --Sz7: 24px;
  --Sz8: 20px;
  --Sz9: 16px;
  --Sz10: 12px;
  --Sz11: Fluid;

  /* spacing variables */
  --Sp1: 2px;
  --Sp2: 4px;
  --Sp3: 8px;
  --Sp4: 12px;
  --Sp5: 16px;
  --Sp6: 24px;
  --Sp7: 32px;
  --Sp8: 40px;
  --Sp9: 64px;
  --Sp10: 96px;

  /* Opacity variables */
  --O1: 5%;
  --O2: 20%;
  --O3: 40%;
  --O4: 80%;
  --O5: 100%;
}

/*Shadow */

.Sh1 {
  box-shadow: 1px 1px 10px 0 var(--CGray2);
  opacity: 0.1;
}

.Sh2 {
  box-shadow: 0px 8px 11px -3px var(--CGray1);
  opacity: 0.1;
}

.ShE {
  box-shadow: 0px 8px 11px -3px var(--CIndicatorE2);
  opacity: 0.1;
}

.ShW {
  box-shadow: 0px 8px 11px -3px var(--CIndicatorW2);
  opacity: 0.1;
}

.ShS {
  box-shadow: 0px 8px 11px -3px var(--CIndicatorS2);
  opacity: 0.1;
}

* {
  box-sizing: border-box;
}

.app {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--CGray2);
}

.background {
  width: 70%;
  height: 100%;
  margin: 20px;
  padding: 30px;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--CGray1);
  border-radius: 0% 0% 5% 5%;
}

.mobile-container {
  /* padding:1rem; */
  /* margin-bottom: 70px;  */
  /* Adjust as needed based on Navbar height */
  background-color: var(--CGray6);
  width: 360px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 10%;
}

/* Hide the default scrollbar for WebKit-based browsers */
.mobile-container::-webkit-scrollbar {
  width: 0.2rem; /* Set the width of the scrollbar */
}

.mobile-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scrollbar thumb */
}

.mobile-container::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

/* Enable scrollbar for Firefox */
.mobile-container {
  scrollbar-width: thin; /* Set the width of the scrollbar */
  scrollbar-color: transparent transparent; /* Set the color of the scrollbar thumb and track */
}

/* Typography */
/* H1 */
.TH1 {
  font-family: var(--F1);
  font-size: var(--Fs6);
  line-height: var(--Lh6);
  font-weight: var(--Fw5);
  text-transform: var(--Tc3);
  /* text-indent: var(--Pi1) !important; */
  /* color: var(--CGray5) !important; */
}

/* H2 */
.TH2 {
  font-family: var(--F1);
  font-size: var(--Fs5);
  line-height: var(--L56);
  font-weight: var(--Fw5);
  /* text-indent: var(--Pi1) !important; */
  text-transform: var(--Tc3);
  /* color: var(--CGray1)!important; */
}

/* H3 */
.TH3 {
  font-family: var(--F1);
  font-size: var(--Fs4);
  line-height: var(--Lh4);
  font-weight: var(--Fw5);
  text-transform: var(--Tc3);
}

/* R1 */
.TR1 {
  font-family: var(--F2);
  font-size: var(--Fs4);
  line-height: var(--Lh4);
  font-weight: var(--Fw3);
}

/* R2 */
.TR2 {
  font-family: var(--F2);
  font-size: var(--Fs3);
  line-height: var(--Lh3);
  font-weight: var(--Fw3);
}

/* S1 */
.TS1 {
  font-family: var(--F2);
  font-size: var(--Fs2);
  line-height: var(--Lh2);
  font-weight: var(--Fw3);
  /* padding: var(--Ps1) ; */
  /* text-indent: var(--Pi1) ; */
  /* color: var(--CGray6) ; */
}

/* S2 */
.TS2 {
  font-family: var(--F2);
  font-size: var(--Fs1);
  line-height: var(--Lh1);
  font-weight: var(--Fw3);
  /* padding: var(--Ps1); */
  /* text-indent: var(--Pi1); */
  /* color: var(--CGray1); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Link1 */
.TLink1 {
  font-family: var(--F2);
  font-size: var(--Fs3);
  line-height: var(--Lh3);
  text-decoration: var(--Td2);
  font-weight: var(--Fw3);
  /* text-indent: var(--Pi1); */
  /* color: var(--CPrimary1); */
}

/* Link2 */
.TLink2 {
  font-family: var(--F2);
  font-size: var(--Fs2);
  line-height: var(--Lh2);
  text-decoration: var(--Td2);
  font-weight: var(--Fw3);
  text-indent: var(--Pi1);
  /* color: var(--CPrimary1); */
}

/* ButtonBig */
.TButtonBig {
  font-family: var(--F2);
  font-size: var(--Fs3);
  line-height: var(--Lh3);
  font-weight: var(--Fw5);
  /* padding: var(--Pi2); */
  /* color: var(--CGray6); */
}

/* ButtonSmall */
.TButtonSmall {
  font-family: var(--F2);
  font-size: var(--Fs2);
  line-height: var(--Lh2);
  font-weight: var(--Fw5);
  /* padding:  var(--Pi2); */
  /* color: var(--CGray6); */
}

.header {
  width: 100%;
  height: var(--Sz45) !important;
  padding: var(--Sp5) var(--Sp6);
  /* background-color: var(--CGray5); */
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.search2 {
  background-color: var(--CGray4);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: var(--Sp5) var(--Sp6);
  width: var(--Sz1);
}

/* Custom arrow style */
.slick-prev,
.slick-next {
  font-size: 24px;
  color: black;
  background-color: #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
}

/* Custom arrow hover style */
.slick-prev:hover,
.slick-next:hover {
  background-color: #333;
}

.custom-form-control {
  width: 240px;
  background-color: transparent;
  border-radius: var(--Br4);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: var(--Sp3) var(--Sp4);
  text-transform: capitalize;
  transition: none;
  box-shadow: none;
  outline: none;
}

.custom-form-control:focus {
  outline: none !important;
  transition: none !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
}

.icon-image-container {
  object-fit: cover;
  width: var(--Sp8) !important;
  height: var(--Sp8) !important;
  padding: var(--Sp2);
  margin: var(--Sp2);
}

.header.sticky-section {
  position: sticky;
  top: 0;
  background-color: #fff; /* Add a background color to the header if needed */
  z-index: 100; /* Ensure the header appears above other content */
}

/* Flexbox styles to align header content */
.header.sticky-section .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--Sz45); /* Adjust the height as needed */
  padding: 0 15px; /* Add padding to create space around the content */
}

/* Style the user and wallet icons */
.header.sticky-section .nav .user-icon,
.header.sticky-section .nav .wallet-icon {
  color: var(--CPrimary1);
  cursor: pointer;
}

/* Style the dropdown title */
.header.sticky-section .nav .dropdown-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--CPrimary1);
}

.header.sticky-section .nav .dropdown-title p {
  margin: 0;
  padding: 0;
  display: inline-block;
  margin-right: 5px; /* Add spacing between icon and text */
}

.nav-bar {
  display: flex;
  color: var(--naviconcolor);
  background-color: var(--navbackground);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1030;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

/* .nav-row-text{
  align-items: center;
  display:flex;
  justify-content: space-between;
} */

/* .nav-container{
  display: flex;  
  align-items: center;
  justify-content: space-between;
  padding:10px;
  margin: 5px;
 } */

.nav-row-container {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
}

.nav-col-container:hover {
  cursor: pointer;
  color: var(--naviconhovercolor);
  .h4 {
    font-size: var(--Fs1);
    font-family: var(--F2);
  }
}

.banner-container-broad {
  width: 700px !important;
}

.section-container-broad {
  width: 750px !important;
  margin: 10px;
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--section-overlay);
  border-radius: 0.4rem;
}

.section {
  /* width: 500px; */
  /* width: 100%; */
  /* margin:40px 10px 40px 10px; */
  /* padding:40px 10px 40px 10px;  */
  width: var(--Sz1);
  padding: var(--Sp5) var(--Sp6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 0.4rem;
}

.category-container {
  /* width: 450px; */
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
  background-color: var(--headercolor);
  border-radius: 0.4rem;
}

.nav-section {
  width: 500px;
  /* margin:10px; */
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-color: var(--navbackground);  */
  border-radius: 0.4rem;
}

.section-container {
  width: 100%;
  height: 600px;
}

.multi-card-container {
  margin: 5px;
  height: 250px;
  width: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
  /* background-color: var(--background-overlay);  */
  /* border-radius: 0.4rem; */
}
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* width: 175px; */
  /* height: 250px;    */
}

.section8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--section-overlay);
  border-radius: 0.4rem;
  width: 320px;
}

.thumbnail-image-container {
  object-fit: contain;
  width: 150px !important;
  height: 150px !important;
}

.icon-image-container {
  object-fit: cover;
  width: 50px !important;
  height: 50px !important;
  padding: 5px;
  margin: 5px;
}

.category-image-container {
  object-fit: cover;
  width: 100px !important;
  height: 100px !important;
  padding: 5px;
  margin: 5px;
}

/* .nav-bar{
  align-items: center;
  color: var(--naviconcolor);
  background-color: var(--navbackground);
  width: 500px;
  justify-content: center;
  position: fixed;
  bottom: 0;
  z-index: 1030;
  height: 70px !important;  
} */

.nav-row-text {
  align-items: center;
  display: flex;
  justify-content: center;
}

.nav-container {
  align-items: center;
  justify-content: center;
  /* padding:10px; */
  /* margin: 5px; */
  width: 100%;
}

.nav-col-container:hover {
  cursor: pointer;
  color: var(--naviconhovercolor);
}

.button {
  /* margin: 2rem; */
  /* padding : 1 rem; */
  justify-content: center;
}

.banner-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: var(--Br4);
  padding: var(--Sp3) var(--Sp4);
  fill: var(--CGray4);
}

.rounded-image {
  object-fit: cover;
  border-radius: 25%;
  width: 100%;
  height: 100%;
}

.banner-container {
  width: 500px !important;
  height: 260px;
  margin: 5% !important;
  padding: 5% !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bg-image {
  /* background-image: url("D://Interaxcion//poc//final_poc_react_backup2//src//images//foodimages//5.jpg"); */
  background-size: cover;
  background-position: center center;
  margin: 0%;
  padding: 0%;
  /* Additional styling if needed */
}

.banner-caption {
  align-items: center;
}

.vertical-text {
  writing-mode: vertical-rl; /*Sets the writing mode to vertical */
  text-orientation: sideways; /* Sets the text orientation to upright */
  white-space: nowrap; /*Prevents text from wrapping */
  transform: rotate(180deg); /*Rotates the text 180 degrees */
  position: relative; /*Allows repositioning of the text */
  text-align: center; /*Aligns the text */
  padding: 0.5rem;
  margin: 0.5rem;
  color: var(--textcolor);
}

.vertical-column-container {
  justify-content: center;
  background-color: purple;
  align-items: center;
  padding: 0%;
}

.col-center {
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.row-center {
  justify-content: space-between;
  align-items: center;
}

.icon-container,
.category-container .nav-link {
  color: var(--textcolor) !important;
}

/* .header-bgimage {
 background-image: url("D://Interaxcion//poc//final_poc_react_backup2//src//images//foodimages//5.jpg");
} */

.icon-container {
  align-items: center;
  justify-content: space-between;
}

/* .slide-item{ */
/* margin :var(--Sp3); */
/* padding:var(--Sp5); */
/* border-radius: 10px; */
/* box-shadow: var(--Sh1); */

/* } */

.card10-row {
  font-size: small;
  font-weight: 500;
  text-align: left;
  margin: 5px;
}

.card10-container {
  width: 320px;
  height: 120px;
}

.card10-image-container {
  object-fit: contain;
  width: 100px !important;
  height: 120px !important;
}

.card8-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.4rem;
}

.card8-carousel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.4rem;
}

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
}

.carousel-indicators .active {
  background-color: #333;
}

.card:hover {
  cursor: pointer;
  /* height: 105%; */
  /* width:101%; */
}

/* .card-img-top{
  height: 100%; 
  object-fit: cover;
  border-radius: 20%;
  
}

.card-img-bottom{
  height: 100%; 
  object-fit: cover;
  border-radius: 20%;
  
} */

.card-img {
  height: 90%;
  object-fit: cover;
  /* border-radius: 20%; */
  margin: 5px;
  padding: 5px;
}

.card-body {
  background-color: var(--text-card-background);
  color: #fff;
}

.card .custom-title {
  font-size: small;
  font-weight: 500;
  text-align: center;
  height: 35px;
  overflow: hidden;
}

.text-card-container {
  font-size: small;
  font-weight: 500;
  text-align: start;
  height: 100% !important;
  width: 100% !important;
  overflow: contain;
  /* margin: 5px; */
}

.text-card-body {
  background-color: var(--textcardbackground);
  color: #fff;
  /* width: 300px !important; */
}

$small-mobile-width: 360px;
$mobile-width: 768px;
$tablet-width: 992px;
$desktop-width: 1441px;

// ----------------------------------------------------------------------

// Mobile Media Query

// ----------------------------------------------------------------------

// example: @include mobile {font-size: 18px;}

@mixin small {
  // this should always come after @include mobile()
  @media (max-width: $small-mobile-width) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}


@mixin responsive($media) {
  @if $media == 'small-mobile' {
    @media (max-width: $small-mobile-width) {
      @content;
    }
  } @else if $media == 'mobile' {
    @media (max-width: $tablet-width) {
      @content;
    }
  } @else if $media == 'tablet' {
    @media (max-width: $tablet-width) {
      @content;
    }
  } @else if $media == 'desktop' {
    @media (min-width: $desktop-width) {
      @content;
    }
  } @else {
    @warn "Invalid media query. Please use 'small-mobile', 'mobile', 'tablet', or 'desktop'.";
  }
}
.feedbackWrapper{
     width: 324px;
     .inputWrapper{
         position: relative;
         .input{
            padding-right: 64px;
         }
         .iconButton{
            position: absolute;
            right: 8px;
            bottom: 8px;
         }
     }
}
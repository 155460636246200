@import './responsive.module.scss';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
input,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: inherit;
  appearance: none;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

img {
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

* {
  box-sizing: border-box;
}

div {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: #20262E; // color yet to decide
    // background: #2C3443; // color yet to decide
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: #2C3443; // color yet to decide
    // background: #C2C8D3; // color yet to decide
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #2C3443; // color yet to decide
    border-radius: 16px;
  }
}


body,
html,
#root {
  height: 100%;
}

body {
  .visible-xs {
    display: none !important;
  }

  .visible-xl {
    display: none !important;
  }

  .hidden {
    display: none !important;
  }

  @media (min-width: $mobile-width) {
    //desktop
    .visible-xl {
      display: flex !important;
    }

    .hidden-xl {
      display: none !important;
    }
  }

  @media (max-width: $mobile-width) {
    //mobile
    .visible-xs {
      display: flex !important;
    }

    .hidden-xs {
      display: none !important;
    }
  }
  .ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.5;
  }
}

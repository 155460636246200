.headerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  // position: sticky;
  // top: 0;
  .link {
    cursor: pointer;
  }

  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .promptWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .prompt {
      margin-right: 8px;
      max-width: 700px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

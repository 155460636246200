.contentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  .subtitle {
    color: #757575;
    margin-bottom: 48px;
  }
}

.image {
  object-fit: cover;
  width: 100%;
  height: 244px;
  fill: var(--CGray4);
}

.carouselItem {
  min-height: 244px;
}

.carousel {
  height: 244px;
  background-color: black;
}

.inputWrapper{
      position: relative;
}
.input {
      resize: none;
      transition: all 0.2s ease;
      border: 1px solid transparent;
      position: relative;
      display: flex;
      background-color: var(--color-black-light);
      align-items: center;
      border-radius: 24px;
      padding: 8px 12px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      box-shadow: none;
      color: var(--color-white);
      border-radius: 8px;
      &::placeholder {
          color: var(--color-grey);
      }
      &:focus {
          outline: 0;
          border-color: var(--color-green-2);
      }
    }
.rightComponent{
      position: absolute;
      right: 0;
}
.headerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;

  .searchIcon {
    margin-right: 16px;
  }

  .filterIcon {
    margin-left: 16px;
  }
}

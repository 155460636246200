.sliderWrapper {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.sliderItem {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  margin: 10px;
  width: auto;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }

  div {
    padding: 2px;
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    font-size: 11px;
    text-align: center;
    font-weight: bold;
  }
}

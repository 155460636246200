@import '../../theme/responsive.module.scss';

.textWrapper{
      font-family: var(--font-family);
}

.p2{
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      @include responsive('mobile') {
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
      }
}
.p1{
      font-size: 20px;
      font-weight: 300;
      line-height: 32px;
      @include responsive('mobile') {
            font-size: 14px;
            line-height: 20px;
      }
}
.p3{
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
}
.h1{
      font-size: 64px;
      font-weight: 500;
      line-height: 72px;
      @include responsive('mobile') {
            font-size: 30px;
            line-height: 36px;
      }
}
.h2{
      font-size: 40px;
      font-weight: 500;
      line-height: 56px;
      @include responsive('mobile') {
            font-size: 20px;
            line-height: 32px;
      }
}
.h3{
      font-size: 28px;
      font-weight: 300;
      line-height: 36px;
      @include responsive('mobile') {
            font-size: 16px;
            line-height: 20px;
      }
}
.heading {
      font-size: 160px;
      font-weight: 500;
      line-height: 160px;
      text-transform: uppercase;
      @include responsive('mobile') {
            font-size: 90.5px;
            line-height: 90.5px;
      }
}
.h2Thin {
      font-size: 40px;
      font-weight: 300;
      line-height: 56px;
      @include responsive('mobile') {
            font-size: 23px;
            line-height: 32.82px;
      }
}
.ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
}